<template>
    <div class="todayActivity p-8">
        <div class="text-right bg-red-200">
            <button @click="closeModal" class="absolute top-2 right-2">
                <i class="bi bi-x"></i>
            </button>
        </div>
        <h1 class="text-4xl font-thin mb-3 text-center">
            Clocker Activity for {{ employee }}
        </h1>
        <div v-if="is_loading" class="text-center">
            <loader />
        </div>
        <div v-else>
            <div class="block space-y-2">
                <span class="font-medium text-center">Activity to start</span>
                <multiselect
                    class="justify-content-center text-left w-full"
                    v-model="activity_selected"
                    placeholder="Select an activity to start"
                    group-values="events"
                    group-label="type"
                    track-by=""
                    label="label"
                    :options="getEventsByLob"
                    :multiple="false"
                    :group-select="false"
                    :allow-empty="false"
                    :clear-on-select="false"
                    :preserve-search="false"
                    deselect-label=""
                ></multiselect>
                <div v-if="commentIsRequired" class="mt-4">
                    <span class="font-medium text-center"
                        >It is required to add a comment about this activity.
                        Please be specific</span
                    >
                    <textarea
                        v-model="activity_comment"
                        class="w-full p-3 resize-none rounded-lg border-[1px]"
                        placeholder="Please add a comment..."
                        maxlength="255"
                        rows="6"
                    ></textarea>
                    <small
                        class="text-gray-500 text-xs ml-2"
                        :class="{ 'text-red-500': commentIsInvalid }"
                    >
                        {{ commentLength }} / 255.
                    </small>
                </div>
                <div class="text-center mt-3">
                    <button
                        :disabled="
                            activity_selected === null ||
                            (commentIsRequired && commentIsInvalid)
                        "
                        type="button"
                        @click="saveNewActivity"
                        class="bg-blue-600 disabled:bg-gray-400 text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { ClientJS } from "clientjs";
import auth from "@/config/user";
import Multiselect from "vue-multiselect";
import Loader from "@/views/components/Loader";
export default {
    props: {
        employee: {
            type: String,
            default: () => auth.currentUser.EmpID,
        },
        lob: {
            type: Number,
            default: 1,
        },
    },

    components: {
        Multiselect,
        Loader,
    },

    data() {
        return {
            is_loading: true,
            activity_selected: null,
            activity_comment: null,
        };
    },

    computed: {
        ...mapState(["clockerUtility"]),
        ...mapGetters(["getIP", "getEventsByLob", "getCurrentShiftObject"]),

        commentLength() {
            return this.activity_comment?.length ?? 0;
        },

        commentIsRequired() {
            return this.activity_selected?.comment_required === 1;
        },

        commentIsInvalid() {
            return (
                this.activity_comment === null ||
                this.activity_comment.trim() === "" ||
                this.activity_comment === undefined ||
                this.activity_comment === "" ||
                this.activity_comment?.length < 5
            );
        },
    },

    methods: {
        ...mapActions([
            "getIPFromClient",
            "startNewActivity",
            "fetchEventsByLob",
            "fetchCommentIsRequired",
        ]),

        saveNewActivity: function () {
            if (
                this.activity_commentIsRequired &&
                this.activity_comment === null
            ) {
                this.$fire({
                    type: "error",
                    title: "Oops! You need to add a comment",
                    showConfirmButton: true,
                });
            }

            let client = new ClientJS();
            let activityObject = {
                activity_id: this.activity_selected.id,
                comment: this.activity_comment,
                emp_id: this.employee.toUpperCase(),
                shift_id: this.getCurrentShiftObject.shift_id,
                url: window.location.pathname,
                Location: this.Location,
                ClientIP: this.getIP,
                ActionTriggered: this.activity_selected.label,
                UserAgent: client.getUserAgent(),
                DeviceOS: client.getOS(),
                DeviceFingerprint: client.getFingerprint(),
            };
            this.is_loading = true;
            this.startNewActivity(activityObject)
                .then(() => {
                    this.$fire({
                        type: "success",
                        title: "New Activity started successfully",
                        showConfirmButton: false,
                        timer: 1300,
                    }).then(() => {
                        this.$emit("toggle", true);
                        this.is_loading = false;
                    });
                })
                .catch((error) => {
                    this.$fire({
                        type: "warning",
                        title: `Something went wrong`,
                        text: `Details: ${
                            error.response.data.message ?? "Unexpected error"
                        }`,
                        showConfirmButton: true,
                    }).then(() => {
                        this.closeModal();
                    });
                });
        },

        closeModal() {
            this.$emit("toggle", true);
        },
    },

    async created() {
        await this.fetchEventsByLob(this.lob);
        await this.getIPFromClient();
        this.is_loading = false;
    },
};
</script>
<style scoped>
#todayActivities___BV_modal_outer_ {
    overflow: hidden;
    z-index: 20001 !important;
}
</style>
