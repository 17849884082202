import { render, staticRenderFns } from "./SupervisorAdjustmentModal.vue?vue&type=template&id=bc0fa51e"
import script from "./SupervisorAdjustmentModal.vue?vue&type=script&lang=js"
export * from "./SupervisorAdjustmentModal.vue?vue&type=script&lang=js"
import style0 from "./SupervisorAdjustmentModal.vue?vue&type=style&index=0&id=bc0fa51e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports