<template>
    <div>
        <loading id='ModalLoader' :active.sync="getLoadingModal" :loader="loader" :background-color="bgcolor"
            :color="color" :is-full-page="fullPage"></loading>
        <div class="block border-b text-center">
            <h3 class="text-2xl font-semibold mb-2 text-left">Change my password</h3>
        </div>
        <div>
            <form @submit="changePassword" autocomplete="off" class="space-y-5 mt-3">
                <div class="grid grid-cols-2 gap-5">
                    <div class="space-y-2">
                        <p class="uppercase text-sm text-slate-800 text-left mb-0">New password</p>
                        <div class="relative block">
                            <input id="new_password"
                                class="border-[1px] w-full rounded-lg px-6 py-2 text-lg border-gray-300 focus:border-gray-700 focus:text-gray-700 pr-9"
                                @input="validatePassword" maxlength="21" placeholder="..."
                                :class="{ 'is-invalid': submitted && $v.newPassword.$error }" v-model="newPassword"
                                :type="showPassword ? 'text' : 'password'" autocomplete="new-password" />

                            <label class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer my-auto">
                                <input type="checkbox" class="hidden" v-model="showPassword" />
                                <i class="bi"
                                    :class="showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill text-gray-400'"></i>
                            </label>
                        </div>
                        <div v-if="submitted && !$v.newPassword.required" class="invalid-feedback">This field is
                            required!</div>
                        <p class="uppercase text-sm text-slate-500 text-right mb-0 mt-1">{{ newPassword.length }}/21</p>
                    </div>
                    <div class="space-y-2">
                        <p class="uppercase text-sm text-slate-800 text-left mb-0">Confirm the new password</p>
                        <div class="relative block">
                            <input
                                class="border-[1px] w-full rounded-lg px-6 py-2 text-lg border-gray-300 focus:border-gray-700 focus:text-gray-700 pr-9"
                                :maxlength="21" placeholder="..."
                                :class="{ 'is-invalid': submitted && $v.confirmPassword.$error }"
                                v-model="confirmPassword" :type="showConfirmationPassword ? 'text' : 'password'"/>
                            <label class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer my-auto">
                                <input type="checkbox" class="hidden" v-model="showConfirmationPassword" />
                                <i class="bi"
                                    :class="showConfirmationPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill text-gray-400'"></i>
                            </label>
                        </div>
                        <div v-if="submitted && !$v.confirmPassword.required & !$v.confirmPassword.sameAsPassword"
                            class="invalid-feedback">Passwords don't match!</div>
                    </div>
                </div>
                <div class="text-slate-400">
                    <div class="relative w-full h-2 rounded-full bg-slate-100 overflow-hidden mb-3">
                        <div class="absolute inset-0 bg-gradient-to-r from-red-600 via-yellow-400 to-green-600"></div>
                        <div class="absolute inset-y-0 right-0 bg-slate-100" :class="'w-[' + score_percentage + '%]'"></div>
                    </div>
                    <p class="m-0 leading-none text-left text-sm ml-2"
                        :class="{ 'text-green-600': newPassword.length >= 12 }">
                        <i class="bi" :class="newPassword.length >= 12 ? 'bi-check' : 'bi-x'"></i> Min. 12 characters
                    </p>
                    <p class="m-0 leading-none text-left text-sm ml-2" :class="{ 'text-green-600': has_uppercase }">
                        <i class="bi" :class="has_uppercase ? 'bi-check' : 'bi-x'"></i> Has a capital letter
                    </p>
                    <p class="m-0 leading-none text-left text-sm ml-2" :class="{ 'text-green-600': has_lowercase }">
                        <i class="bi" :class="has_lowercase ? 'bi-check' : 'bi-x'"></i> Has a lowercase letter
                    </p>
                    <p class="m-0 leading-none text-left text-sm ml-2" :class="{ 'text-green-600': has_number }">
                        <i class="bi" :class="has_number ? 'bi-check' : 'bi-x'"></i> Has a number
                    </p>
                    <p class="m-0 leading-none text-left text-sm ml-2" :class="{ 'text-green-600': has_special }">
                        <i class="bi" :class="has_special ? 'bi-check' : 'bi-x'"></i> Has a special character
                    </p>
                    <p class="m-0 leading-none text-left text-sm ml-2" :class="{ 'text-green-600': !has_not_user }">
                        <i class="bi" :class="!has_not_user ? 'bi-check' : 'bi-x'"></i> Must not contain your username
                    </p>
                </div>
                <button type="submit"
                    class="bg-c-primary text-gray-50 disabled:bg-gray-400 disabled:text-gray-200 text-sm px-6 py-2 rounded-xl uppercase font-semibold space-x-2"
                    :disabled="!(score_percentage == 0 && confirmPassword == newPassword)">
                    <i class="bi bi-send-fill"></i>
                    Save New Password
                </button>
            </form>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required, sameAs } from 'vuelidate/lib/validators';
export default {
    components: { Loading },
    data() {
        return {
            submitted: false,
            fullPage: true,
            color: "#F2F2F2",
            bgcolor: "#000000",
            loader: 'dots',
            newPassword: "",
            confirmPassword: "",
            postData: [],
            terminationData: [],
            score_percentage: 100,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
            has_not_user: false,
            showPassword: false,
            showConfirmationPassword: false,
        };
    },
    computed: {
        ...mapState(["clockerUsers", "session"]),
        ...mapGetters(['getLoadingModal'])
    },
    validations: {
        newPassword: { required },
        confirmPassword: {
            sameAsPassword: sameAs('newPassword')
        },
    },
    props: {
        profileInfo: Object
    },
    watch: {
        newPassword(value) {
            this.newPassword = value.replace(/[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};:'",.<>/?`~|\\]/g, "");
        },
        confirmPassword(value) {
            this.confirmPassword = value.replace(/[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};:'",.<>/?`~|\\]/g, "");

        },
    },
    methods: {
        ...mapActions(["setNewPassword", "logoutUser"]),

        validatePassword() {
            this.has_number = (/\d/).test(this.newPassword);
            this.has_lowercase = (/[a-z]/).test(this.newPassword);
            this.has_uppercase = (/[A-Z]/).test(this.newPassword);
            this.has_special = (/[ `!@#$%^&*()_+\-={};':"\\|,.<>?~]/).test(this.newPassword);
            this.has_not_user = (this.newPassword.toLowerCase()).includes(this.profileInfo.EmpID.toLowerCase());

            var score = 0;
            score = score + (this.newPassword.length >= 12 ? 16.66 : 0);
            score = score + (this.has_number ? 16.66 : 0);
            score = score + (this.has_lowercase ? 16.66 : 0);
            score = score + (this.has_uppercase ? 16.66 : 0);
            score = score + (this.has_special ? 16.66 : 0);
            score = score + (this.has_not_user ? 0 : 16.7);

            this.score_percentage = 100 - score;
        },

        changePassword() {
            event.preventDefault();
            this.submitted = true;

            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$fire({
                    type: 'error',
                    title: 'Oops! there are some errors',
                    showConfirmButton: true
                })
                return;
            }

            const postData = {
                employeeID: this.profileInfo.EmpID,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword,
            };

            this.$confirm("You're about to modify your password. Do you want to continue?", "Password Change", "warning").then((result) => {
                if (result) {
                    this.setNewPassword(postData).then(response => {
                        if (response.status == 200) {
                            this.$fire({
                                type: "success",
                                title: "Password changed",
                                text: "You'll be logged out",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(() => {
                                this.$emit('closePassword', true);
                                this.logout();
                            });
                        } else if (response.status == 400) {
                            this.$fire({
                                type: 'error',
                                title: response.msg,
                                showConfirmButton: true
                            })
                        } else if (response.status == 201) {
                            this.$fire({
                                type: 'error',
                                title: response.data.msg,
                                showConfirmButton: true
                            })
                        }
                    });
                }
            })
        },
        logout: async function () {
            try {
                this.logoutUser().then(() => {
                    setTimeout(window.location.reload.bind(window.location), 500);
                });
            } catch (error) {
                this.$fire({
                    type: 'error',
                    title: 'There was an error loging you out',
                    showConfirmButton: true
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped></style>