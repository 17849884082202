<template>
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity ease-in-out duration-300 backdrop-blur-sm"
            @click="closeOnOutsideClick"></div>
        <div class="relative z-60 bg-white p-3 rounded-xl shadow-lg shrink-0" :class="width">
            <button class="text-right w-full" @click="close">
                <i class="bi bi-x text-xl"></i>
            </button>
            <div class="px-6 pb-2">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false,
            required: false
        },
        width: {
            type: String,
            default: 'w-1/2'
        },
        outsideClick: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        closeOnOutsideClick() {
            if (this.outsideClick) this.close();
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>