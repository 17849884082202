<template>
  <div class="flex flex-col size-full overflow-hidden">
    <div class="w-full bg-white flex flex-row justify-between border-b-[1px]">
      <div class="flex flex-row transform-all duration-300">
        <button  :disabled="!hasQueueFilter || sending" name="hello" class="py-2 text-sm pl-2 pr-4 text-[#000544]" @click.prevent="toggleSelect">
          <span v-if="hasQueueFilter">Select Mode: {{selectMode ? 'On' : 'Off'}}</span>
          <span v-if="!hasQueueFilter">Filter by queue to use Select Mode</span>
        </button>
        <button :disabled="sending" v-if="activateSelectAll" class="text-sm pl-2 pr-4 text-[#000544]" @click.prevent="selectAll">
          Select All
        </button>
        <button :disabled="sending" v-if="activateDeselectAll" class="text-sm pl-2 pr-4 text-[#000544]" @click.prevent="deselectAll">
          Deselect All
        </button>
      </div>
      <div>
        <button @click.prevent="assignTicket" :class="{'block opacity-100': selectMode, 'invisible opacity-0': !selectMode}" :disabled="!selectMode || sending || checkMarkedTickets.length === 0" class="text-sm transition-all ease-in-out duration-300 hover:bg-[#3276e7] bg-[#3c82f6] text-white py-1 px-2 rounded-md mx-2 mb-1 disabled:bg-gray-300 group">
          Assign to
          <select @click.stop :disabled="sending" v-model="assignTo" class="group-disabled:bg-gray-400 bg-[#2247b7] py-1 rounded-md">
            <option class="content-center text-sm" :value="user.ID" v-for="user in assignable" :key="`assign_${user.ID}`">
              {{user.EmpID}}
            </option>
          </select>
        </button>
      </div>
    </div>
    <div class="size-full overflow-auto bg-white">
      <table
        class="table light-table shadow-none bg-white m-0">
        <tbody v-if="getTickets.length">
          <tr :id="ticket.ID" v-for="(ticket, index) in getTickets"
              :key="ticket.ID" class="border-y-[1px]">
            <td v-if="selectMode" class="content-center text-center">
              <input :disabled="sending" @click="refreshLastTouched(index, $event)" :id="`ck_${index}`" type="checkbox" :value="ticket.ID" v-model="checkMarkedTickets">
            </td>
            <td :title="ticket.ID"
                class="text-sm capitalize text-clip overflow-hidden content-center">
              <div class="flex flex-row items-center space-x-4">
                <div class="flex flex-col">
                  <vs-tooltip
                    :text="'(' + ticket.IssueDate + ') ' + ticket.TicketDescription"
                    class="flex">
                    <i
                      class="bi text-xl bi-info-circle-fill text-blue-500"></i>
                  </vs-tooltip>
                  <div class="flex text-center justify-content-center">
                    <i v-if="ticket.isClose"
                       class="bi text-xl bi-x-circle-fill text-red-500"></i>
                    <i v-else
                       class="bi text-xl bi-check-circle-fill text-green-500"></i>
                  </div>
                </div>
                <span title="Ticket id" class="font-semibold"># {{ ticket.ID }}</span>
              </div>
            </td>
            <td :title="ticket.AffectedEmployee"
                class="text-sm capitalize text-clip overflow-hidden">
              <div class="flex flex-col">
                <span title="Affected employee">{{ ticket.AffectedEmployee }}</span>
                <span title="Campaign" class="font-semibold">{{ ticket.Campaign }}</span>
                <span title="Created at">{{ ticket.CreatedAt | moment("YYYY-MM-DD HH:mm") }}</span>
              </div>
            </td>
            <td class="text-sm capitalize text-clip overflow-hidden">
              <div class="flex flex-col">
                <span title="Queue" class="font-semibold">{{ ticket.queueName }}</span>
                <span title="Category">{{ ticket.categoryName }}</span>
                <span title="Subcategory">{{ ticket.subCategoryName }}</span>
              </div>
            </td>
            <td
              class="text-sm capitalize text-clip overflow-hidden">
              <div class="flex flex-col">
                <span class="font-semibold" :class="{'text-[#ef4444]': ticket.EmpID === null}" title="Assigned to">{{ ticket.EmpID == null ? "Not Assigned" : ticket.EmpID }}</span>
                <span title="Status">{{ticket.PriorityLabel}}</span>
                <span title="Reported by">Reported by: {{ ticket.ReportedBy }}</span>
              </div>
            </td>
            <td :title="ticket.isClose">
            </td>
            <td class="flex flex-row flex-1 space-x-2">
              <i class="bi bi-pen-fill text-gray-300"
                 v-if="ticket.isClose == 1"></i>
              <vs-tooltip v-else text="Edit Ticket">
                <i class="bi bi-pen-fill"
                   v-b-modal="sending ? '' : 'editTicket'+ticket.ID"></i>
              </vs-tooltip>
              <vs-tooltip v-if="ticket.isClose == '1'"
                          text="Open Ticket">
                <i @click="openTicket(ticket.ID)"
                   class="bi bi-unlock-fill"></i>
              </vs-tooltip>
              <vs-tooltip v-else text="Close Ticket">
                <i class="bi bi-lock-fill"
                   v-b-modal="sending ? '' : 'closeTicket'+ticket.ID"></i>
              </vs-tooltip>
            </td>
            <!-- Modals -->
            <b-modal :ref="'editModal'+ticket.ID"
                     :id="'editTicket'+ticket.ID" hide-footer centered>
              <EditTicket @update="updateTickets" @closeModal='hideModal(ticket.ID)'
                          :queueId="parseInt(ticket.QueueID)"
                          :thicketId="ticket.ID"></EditTicket>
            </b-modal>
            <b-modal :ref="'closeModal'+ticket.ID"
                     :id="'closeTicket'+ticket.ID" hide-footer centered>
              <CloseTicket @closeModal='hideModal(ticket.ID)'
                           :ticketId="ticket.ID"></CloseTicket>
            </b-modal>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10">No records found.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import EditTicket from '@/views/components/Tickets/modals/editTicket';
import CloseTicket from '@/views/components/Tickets/modals/closeTicket';
import {mapGetters, mapState, mapActions} from 'vuex'

export default {

    components: {
        EditTicket,
        CloseTicket
    },
    
    props: {
        filters: {
            required: true,
            type: Object,
        }
    },

    data() {
        return {
            sending: false,
            selectMode: false,
            checkMarkedTickets: [],
            lastTouchedID: null,
            keyword: '',
            fullPage: true,
            color: "#F2F2F2",
            bgcolor: "#000000",
            loader: 'dots',
            isDisabled: true,
            assignable: [],
            assignTo: null,
        }
    },

    computed: {
        ...mapState(['tickets']),
        ...mapGetters(['getTickets', 'getAssings']),
        
        hasQueueFilter() {
            return !['undefined', null].includes(this.filters?.queue);
        },

        activateSelectAll() {
            return this.selectMode === true &&
                this.checkMarkedTickets.length < this.getTickets.length;
        },

        activateDeselectAll() {
            return this.selectMode === true &&
                this.checkMarkedTickets.length === this.getTickets.length;
        }
    },

    created() {
        //   this.$store.dispatch('fetchtickets');
    },

    watch: {
        getTickets() {
            this.checkMarkedTickets = [];
        },
    },

    methods: {
        ...mapActions(
            [
                'filterTicketsRequest',
                'EditTicket',
                'fetchTicketsPerQueue',
                'changeStatus',
                'fetchAssignList',
            ]
        ),
        
        updateTickets() {
            this.sending = true;
            return this.filterTicketsRequest(this.filters).then(
                () => {
                    this.sending = false;
                }
            );
        },

        assignTicket() {
            this.sending = true;
            let objData = {
                editType: 'info',
                selectedTickets: this.checkMarkedTickets,
                assignTo: this.assignTo,
                ticketPriorityID: 7,
            }

            this.EditTicket(objData).then(() => {
                this.$fire({
                    type: 'success',
                    title: "Information Sent!",
                    text: "Changes applied to the ticket",
                    showConfirmButton: false,
                    timer: 1500,
                })
                
                return this.updateTickets();
            }).catch(() => {
                this.$fire({
                    type: 'warning',
                    title: "Something went wrong",
                    text: "Please try again or report to a administrator",
                    showConfirmButton: false,
                    timer: 1500,
                })
            }).finally(
                () => {
                    this.sending = false;
                }
            );
        },

        checkmarkValues(valueArray) {
            this.checkMarkedTickets = [
                ...new Set([...this.checkMarkedTickets, ...valueArray]),
            ];
        },

        uncheckValues(valueArray) {
            this.checkMarkedTickets = this.checkMarkedTickets.filter(
                x => !valueArray.includes(x),
            );
        },

        getCheckboxes() {
            let checkboxes = document.querySelectorAll('[id^="ck"]');

            return Array.from(checkboxes);
        },

        getCheckboxValues() {
            return this.getCheckboxes().map(x => parseInt(x.value));
        },

        selectAll() {
            this.checkmarkValues(this.getCheckboxValues());
        },

        deselectAll() {
            this.uncheckValues(this.checkMarkedTickets);
        },

        refreshLastTouched(currentID, event) {
            if (this.lastTouchedID !== null && event.shiftKey === true) {
                let checkboxes = this.getCheckboxes()

                const toCheck = checkboxes[this.lastTouchedID].checked;

                let actionable = this.getCheckboxValues().slice(
                    Math.min(currentID, this.lastTouchedID) + 1,
                    Math.max(currentID, this.lastTouchedID),
                );

                if (toCheck === true) {
                    this.checkmarkValues(actionable);
                }

                if (toCheck === false) {
                    this.uncheckValues(actionable);
                }
            }

            this.lastTouchedID = currentID;
        },

        toggleSelect() {
            this.checkMarkedTickets = [];
            this.selectMode = !this.selectMode;
            
            if (this.selectMode === true) {
                this.sending = true;
                this.fetchAssignList({queueId: this.filters.queue}).then(
                    () => {
                        this.assignable = JSON.parse(JSON.stringify(this.getAssings));
                        this.assignTo = this.assignable[0].ID;
                    }
                ).finally(
                    () => {this.sending = false;}
                );
            }
        },

        hideModal: function (data) {
            let referencia = 'editModal' + data;
            let referencia2 = 'closeModal' + data;
            this.$refs[referencia][0].hide(data);
            this.$refs[referencia2][0].hide(data);
        },

        openTicket(data) {
            if (this.sending) {
                return;
            }
            this.$confirm("You'll be able to edit the specs later", "Do you want to re-open this ticket?", "question").then((result) => {
                if (result) {
                    let objData = {ticketSelected: data}
                    this.changeStatus(objData).then(() => {
                        this.$emit('toggle');
                        this.$fire({
                            type: "success",
                            title: "Ticket Re-Opened",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(() => {
                            this.$emit('updateTable', true);
                        });
                    });
                }
            })
        },
    },
}

</script>
<style lang="scss" scoped>
.md-field {
  max-width: 300px;
}

.TableSearch {
  margin: 2em 0 0 0 !important;
}

.material-icons.viewIcon {
  font-size: 2em;
  cursor: pointer;
}

.btn-primary.disabled, .btn-primary:disabled {
  cursor: no-drop;
}
</style>