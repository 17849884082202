import axios from '@/config/axios'
import { API_URL } from '../actions/config'
const state = {
    pagination:         [],
    employees:          [],
    queues:             [],
    categories:         [],
    subCategories:      [],
    subQuestions:       [],
    subQuestionsArray:  [],
    tickets:            [],
    ticketInfo:         [],
    priorities:         [],
    assings:            [],
    ticket:             false,
    ticketMsgs:         [],
    ticketStatus:       false,
    reasons:            null,
    employeeTickets:    []
};
const getters = {
    getFilterTicketsPagination:     (state) => state.pagination,
    getEmployeesperCampaign:        (state) => state.employees,
    getQueuesList:                  (state) => state.queues,
    getCategoriesList:              (state) => state.categories,
    getSubCategoriesList:           (state) => state.subCategories,
    getSubQuestions:                (state) => state.subQuestions,
    getSubQuestionsArray:           (state) => state.subQuestionsArray,
    getTickets:                     (state) => state.tickets,
    getTicketInfo:                  (state) => state.ticketInfo,
    getPriorities:                  (state) => state.priorities,
    getAssings:                     (state) => state.assings,
    getTicketMsgs:                  (state) => state.ticketMsgs,
    getTicketStatus:                (state) => state.ticketStatus,
    getCloseTicketReasons:          (state) => state.reasons,
    getEmployeeTickets:             (state) => state.employeeTickets
};
const actions = {
    async fetchEmployeesperCampaign({commit}){
        const endpoint = API_URL + 'api/v2/tickets/employeesPerCampaign';
        await axios.get( endpoint).then((res) =>{
            commit('setEmployeesObject', res.data.activitiesGrouped);  
        }); 
    },

    async fetchTickets({commit}){
        const endpoint = API_URL + 'api/v2/tickets/showTickets';
        await axios.get( endpoint).then((res) =>{
            commit('setTickets', res.data.msg);  
        }); 
    },

    async fetchEmployeeTickets({commit}, empId){
        const endpoint = API_URL + 'api/v2/tickets/showEmpTickets/'+empId;
        await axios.get( endpoint).then((res) =>{
            commit('setTickets', res.data.msg);  
        }); 
    },

    async fetchTicketQueues({commit}){
        const endpoint = API_URL + 'api/v2/tickets/ticketQueuesList';
        await axios.get( endpoint).then((res) =>{
            commit('setQueues', res.data.queues);  
        }); 
    },

    async filterTicketsRequest({commit},data){
        let formData =  Object.keys(data).length !== 0 && data.constructor === Object ? filterObject(data, item => item !== 0 && item !== null && item !== ""): data;
        let response = await axios.get(API_URL + `api/v2/tickets/filters/filterTicketsRequest`, {params: formData});
        commit('setTickets',response.data.ticketsRequests);
        commit('setFilterTicketsPagination', {meta: response.data.meta, links: response.data.links});
    return response;
    },

    async fetchTicketCategories({commit}, objData){
        const endpoint = API_URL + 'api/v2/tickets/ticketCategoriesList';
        await axios.post( endpoint, objData).then((res) =>{
            commit('setCategories', res.data.categories);  
        }); 
    },

    async fetchTicketSubCategories({commit}, objData){
        const endpoint = API_URL + 'api/v2/tickets/ticketSubCategoriesList';
        await axios.post( endpoint, objData).then((res) =>{
            commit('setSubCategories', res.data.subCategories);  
        }); 
    },

    async fetchTicketsByEmployeeId({commit}, {id, category}){
        const endpoint = API_URL + `api/v2/tickets/filter/employee/${id}/category/${category}`;
        await axios.get( endpoint).then((res) =>{
            commit('setEmployeeTickets', res.data.tickets);  
        }); 
    },

    

    async fetchTicketSubCategoriesById({commit}, id){
        const endpoint = API_URL + 'api/v2/tickets/ticketSubCategoriesList';
        await axios.post( endpoint, {categorySelected:id}).then((res) =>{
            commit('setSubCategories', res.data.subCategories);  
        }); 
    },

    async fetchTicketSubQuestions({commit}, objData){
        const endpoint = API_URL + 'api/v2/tickets/ticketSubQuestions';
        await axios.post( endpoint, objData).then((res) =>{
            commit('setSubQuestions', res.data.questions);  
        }); 
    },

    async fetchTicketSubQuestionsArray ({ commit }, objData ){
        const endpoint = API_URL + 'api/v2/tickets/ticketSubQuestionsArray';
        const response = await axios.post(endpoint,objData)
        commit('setSubQuestionsArray', response.data);
        return response.data
    },

    async sendNewTicket ({commit}, data ){        
        const endpoint = API_URL + 'api/v2/tickets/sendTicket'
        const response = await axios.post(endpoint, data);
        commit('setTickets', null);
        return response
    },

    async fetchMyTickets({commit}){
        const endpoint = API_URL + 'api/v2/tickets/showMyTickets';
        await axios.get( endpoint).then((res) =>{
            commit('setTickets', res.data.msg);  
        }); 
    },

    async fetchReviewTickets({commit}){
        const endpoint = API_URL + 'api/v2/tickets/showReviewTickets';
        await axios.get( endpoint).then((res) =>{
            commit('setTickets', res.data.msg);  
        }); 
    },

    async fetchThisTicketInfo({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/showThisTicketInfo';
        await axios.post( endpoint, data).then((res) =>{
            commit('setTicketInfo', res.data.msg[0]);  
        }); 
    },

    async fetchThisTicketMessages({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/thisTicketMessages';
        await axios.post( endpoint, data).then((res) =>{
            commit('setTicketMsgs', res.data.msg);  
        }); 
    },

    async fetchTicketsPerQueue({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/ticketsPerQueue';
        await axios.post( endpoint, data).then((res) =>{
            commit('setTickets', res.data.msg);  
        }); 
    },

    async fetchPrioritiesList({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/prioritiesList';
        await axios.post( endpoint, data).then((res) =>{
            commit('setPriorities', res.data.msg);  
        }); 
    },

    async fetchAssignList({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/assignList';
        await axios.post( endpoint, data).then((res) =>{
            commit('setAssings', res.data.msg);  
        }); 
    },

    async EditTicket({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/editTicket';
        return await axios.post( endpoint, data).then( (res) =>{
            commit('setTicket', true);     
            return res;
        });
    },

    async changeStatus({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/changeStatus';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setTicket', true);     
            return res;
        });
        return response;
    },

    async sendTicketMessage({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/sendTicketMessage';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setTicketMsgs', true);     
            return res;
        });
        return response;
    },

    async changeWaiting({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/changeWaiting';
        let response = await axios.post( endpoint, data ).then( (res) =>{
            commit('setTicket', true);     
            return res;
        });
        return response;
    },

    async checkIfCanReopen ({ commit }, data ){
        const endpoint = API_URL + 'api/v2/tickets/checkIfCanReopen';
        const response = await axios.post(endpoint,data).then((result) => {
            commit('setTicketStatus', true);
            return result;
        });
        return response;
    },

    async createChildTickets({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/createChildTickets';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setTicket', true);     
            return res;
        });
        return response;
    },

    async fetchCloseTicketReasons({commit}){
        const endpoint = API_URL + 'api/v2/tickets/cancelTicketReasons';
        await axios.get( endpoint).then((res) =>{
            commit('setReasons', res.data.reasons);  
        }); 
    },

    // Used for Incidences requests

    async verifyTerminationDates({commit}, data){
        const endpoint = API_URL + 'api/v2/tickets/verifyTerminationDates';
        let response = await axios.post( endpoint, data).then( (res) =>{
            commit('setTicket', true); 
            return res;
        });
        return response;
    },

    async fetchTicketTemplate({commit}, objData){
        const endpoint = API_URL + 'api/v4/tickets/fetchTemplate';
        await axios.post( endpoint, objData).then((res) =>{
            commit('setSubQuestions', res.data.template);  
        }); 
    },

    async saveTicketTemplate({commit}, objData){
        const endpoint = API_URL + 'api/v4/tickets/saveTemplate';
        await axios.post( endpoint, objData).then(() =>{
            commit('setTicketStatus', true);
        });
    },

};
const mutations = {
    setFilterTicketsPagination:     (state, pagination)         =>  (state.pagination           = pagination),
    setEmployeesObject:             (state, employees)          =>  (state.employees            = employees),
    setQueues:                      (state, queues)             =>  (state.queues               = queues),
    setCategories:                  (state, categories)         =>  (state.categories           = categories),
    setSubCategories:               (state, subCategories)      =>  (state.subCategories        = subCategories),
    setSubQuestions:                (state, subQuestions)       =>  (state.subQuestions         = subQuestions),
    setSubQuestionsArray:           (state, subQuestionsArray)  =>  (state.subQuestionsArray    = subQuestionsArray),
    setTickets:                     (state, tickets)            =>  (state.tickets              = tickets),
    setTicket:                      (state, ticket)             =>  (state.ticket               = ticket),
    setTicketInfo:                  (state, ticketInfo)         =>  (state.ticketInfo           = ticketInfo),
    setTicketMsgs:                  (state, ticketMsgs)         =>  (state.ticketMsgs           = ticketMsgs),
    setPriorities:                  (state, priorities)         =>  (state.priorities           = priorities),
    setAssings:                     (state, assings)            =>  (state.assings              = assings),
    setTicketStatus:                (state, ticketStatus)       =>  (state.ticketStatus         = ticketStatus),
    setReasons:                     (state, reasons)            =>  (state.reasons = reasons),
    setEmployeeTickets:             (state, tickets) => (state.employeeTickets = tickets)
};
export default {
    state,
    getters,
    actions,
    mutations
};

let filterObject = (obj, predicate) => Object.keys(obj).filter( key => predicate(obj[key])).reduce( (res, key) => (res[key] = obj[key], res), {} );