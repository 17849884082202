import Vue                  from 'vue'
import App                  from './App.vue'
import router               from './router'
import VueTheMask           from 'vue-the-mask'
import VueSimpleAlert       from "vue-simple-alert";
import Vuelidate            from 'vuelidate'
// import DisableAutocomplete  from 'vue-disable-autocomplete';
import store                from './store/store'
import Vuesax               from 'vuesax'
import VueMoment            from 'vue-moment'
import { Datetime }         from 'vue-datetime'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAgile             from 'vue-agile'
import VueTimepicker        from 'vue2-timepicker'
import {version}            from '../package.json';
import { ClientJS }         from 'clientjs';
import Echo                 from 'laravel-echo'
import Multiselect          from 'vue-multiselect'
import Toasted              from 'vue-toasted';
import VueSplide from '@splidejs/vue-splide';
import Permissions from '@/config/mixins';
import VueYoutube from 'vue-youtube'
import * as VueGoogleMaps from 'vue2-google-maps'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuesax/dist/vuesax.css'
import 'vue-datetime/dist/vue-datetime.css'
import 'material-icons/iconfont/material-icons.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './index.css';

import GlobalModal from '@/views/components/Modal/modalBase'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBHPT7ffpH3VgQRECvjEzY2O6qLKRhbjBQ',
    libraries: 'places',
  }
});
Vue.use(VueYoutube)
Vue.use(Toasted)
Vue.use(Multiselect);
Vue.use(VueAgile)
Vue.use(VueMoment,{});
Vue.component('multiselect', Multiselect)
Vue.use(Vuesax, {})
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTheMask)
Vue.use(VueSimpleAlert);
Vue.use(Vuelidate)
// Vue.use(DisableAutocomplete);
Vue.use(Datetime);
Vue.use(ClientJS);
Vue.use(VueTimepicker);
Vue.use( VueSplide );
Vue.mixin(Permissions);
window.EventBus = new Vue();
Vue.component("GlobalModal", GlobalModal);

window.Pusher = require('pusher-js');
window.version = version;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: false
});


new Vue({
  router,  
  store,
  render: h => h(App)
}).$mount('#app')
