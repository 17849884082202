<template>
    <div v-if="isLoading" class="LoadingScreen">
        <div class="text-center">
            <p class="font-italic font-weight-bold">Loading...</p>
            <p class="font-italic font-weight-bold">
                <b-spinner
                    variant="success"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
                <b-spinner
                    variant="danger"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
                <b-spinner
                    variant="warning"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
            </p>
        </div>
    </div>
    <div v-else class="editInfoContainer">
        <div class="d-block text-center">
            <h3
                class="font-sans text-4xl tracking-wide bg-gray-50 font-bold text-gray-700 mt-2"
            >
                Open a Ticket
            </h3>
        </div>

        <div>
            <div class="md:grid md:grid-cols-6">
                <div class="mt-2 md:mt-0 md:col-span-6">
                    <form action="#">
                        <h4 class="font-medium leading-6 text-gray-700">
                            Information
                        </h4>
                        <div class="bg-gray-50 px-4 py-4 space-y-6 sm:p-6">
                            <div class="grid grid-cols-6">
                                <!-- Queue -->
                                <div class="sm:col-span-3">
                                    <label
                                        for="ticket_queue"
                                        class="text-gray-600 font-semibold text-lg"
                                    >
                                        Select a Queue:
                                    </label>

                                    <div
                                        class="items-center mt-1 flex align-items-center justify-content-start"
                                    >
                                        <select
                                            required
                                            id="FormId0"
                                            @change="loadCategories"
                                            v-model="ticket_info.ticket_queue"
                                            class="w-5/6 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 rounded-md py-2"
                                        >
                                            <optgroup label="Queue">
                                                <option hidden value="null">
                                                    Select a Queue...
                                                </option>
                                                <option
                                                    v-for="queue in getQueuesList"
                                                    :value="queue"
                                                    :key="queue.id"
                                                >
                                                    {{
                                                        queue.queueName.toUpperCase()
                                                    }}
                                                </option>
                                            </optgroup>
                                        </select>
                                    </div>

                                    <p
                                        id="FormId0Text"
                                        class="font-semibold mt-2 text-sm text-gray-500"
                                    >
                                        Please, select the queue that will
                                        receive the ticket.
                                    </p>
                                </div>
                                <div class="sm:col-span-3"></div>

                                <!-- Issue Category and Subcategory -->
                                <div class="sm:col-span-3">
                                    <label
                                        for="ticket_category"
                                        class="text-gray-600 font-semibold text-lg"
                                    >
                                        Category:
                                    </label>

                                    <div
                                        class="items-center mt-1 flex align-items-center justify-content-start"
                                    >
                                        <select
                                            :disabled="!this.showCategories"
                                            required
                                            id="FormId1"
                                            @change="loadSubCategories"
                                            v-model="
                                                ticket_info.ticket_category
                                            "
                                            class="w-5/6 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 rounded-md py-2"
                                        >
                                            <optgroup label="Category">
                                                <option hidden value="null">
                                                    {{
                                                        !this.showCategories
                                                            ? "Select a Queue first..."
                                                            : "Select a Category..."
                                                    }}
                                                </option>
                                                <option
                                                    v-for="category in getCategoriesList"
                                                    :value="category.id"
                                                    :key="category.id"
                                                >
                                                    {{
                                                        category.categoryName.toUpperCase()
                                                    }}
                                                </option>
                                            </optgroup>
                                        </select>
                                    </div>

                                    <p
                                        id="FormId1Text"
                                        class="font-semibold mt-2 text-sm text-gray-500"
                                    >
                                        Please, select the category of the
                                        issue.
                                    </p>
                                </div>
                                <div class="sm:col-span-3">
                                    <label
                                        for="ticket_subcategory"
                                        class="text-gray-600 font-semibold text-lg"
                                    >
                                        Sub-Category:
                                    </label>

                                    <div
                                        class="items-center mt-1 flex align-items-center justify-content-start"
                                    >
                                        <select
                                            :disabled="!this.showSubCategories"
                                            required
                                            id="FormId2"
                                            @change="loadSubQuestions"
                                            v-model="
                                                ticket_info.ticket_subcategory
                                            "
                                            class="w-5/6 bg-transparent text-gray-400 hover:text-gray-700 active:text-gray-700 rounded-md py-2"
                                        >
                                            <optgroup label="Sub-category">
                                                <option hidden value="null">
                                                    {{
                                                        !this.showSubCategories
                                                            ? "Select a Category first..."
                                                            : "Select a Sub-Category..."
                                                    }}
                                                </option>
                                                <option
                                                    v-for="subCategory in getSubCategoriesList"
                                                    :value="subCategory.id"
                                                    :key="subCategory.id"
                                                >
                                                    {{
                                                        subCategory.subCategoryName.toUpperCase()
                                                    }}
                                                </option>
                                            </optgroup>
                                        </select>
                                    </div>

                                    <p
                                        id="FormId2Text"
                                        class="font-semibold mt-2 text-sm text-gray-500"
                                    >
                                        Please, select the sub-category of the
                                        issue.
                                    </p>
                                </div>
                                <!-- Employee Information -->
                                <div class="sm:col-span-3 mr-2">
                                    <label
                                        for="ticket_employeeId"
                                        class="text-gray-600 font-semibold text-lg"
                                    >
                                        Issue Employee:
                                    </label>
                                    <div
                                        id="FormId3"
                                        class="w-5/6 items-center mt-1 flex align-items-center justify-content-start rounded-md"
                                    >
                                        <mini-search-dropdown
                                            :options="employeeList"
                                            :labelKey="'EmpID'"
                                            placeholder="Select the issued employee..."
                                            v-model="
                                                ticket_info.ticket_employeeId
                                            "
                                            :customFormatter="
                                                formatEmployeeLabel
                                            "
                                        />
                                    </div>
                                    <p
                                        id="FormId3Text"
                                        class="font-semibold mt-2 text-sm text-gray-500"
                                    >
                                        Please, specify the employee.
                                    </p>
                                    <div class="flex mt-1">
                                        <label class="inline-flex items-center">
                                            <input
                                                v-model="ticket_info.ticket_wfh"
                                                type="checkbox"
                                                class="focus:ring-blue-400 h-4 w-4 rounded"
                                            />
                                            <span
                                                class="ml-2 font-semibold text-lg text-gray-600"
                                                >This Employee is Working from
                                                Home</span
                                            >
                                        </label>
                                    </div>
                                    <div class="flex mt-1">
                                        <label class="inline-flex items-center">
                                            <input
                                                v-model="emailNotification"
                                                type="checkbox"
                                                class="focus:ring-blue-400 h-4 w-4 rounded"
                                            />
                                            <span
                                                class="ml-2 font-semibold text-lg text-gray-600"
                                                >Send Email Notification?</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div class="sm:col-span-3 ml-2">
                                    <label
                                        for="ticket_issuedate"
                                        class="text-gray-600 font-semibold text-lg"
                                    >
                                        Issue Date:
                                    </label>

                                    <div
                                        id="FormId4"
                                        class="w-5/6 items-center mt-1 flex align-items-center justify-content-start rounded-md"
                                    >
                                        <datepicker
                                            class="form-control bg-transparent w-5/6 sm:text-sm border-gray-500"
                                            :format="customFormatter"
                                            placeholder="yyyy-mm-dd"
                                        ></datepicker>
                                    </div>

                                    <p
                                        id="FormId4Text"
                                        class="font-semibold mt-2 text-sm text-gray-500"
                                    >
                                        Please, specify the date
                                    </p>
                                </div>
                            </div>

                            <!-- Free Comment -->
                            <div class="row col-12">
                                <label
                                    for="ticket_comment"
                                    class="text-gray-600 font-semibold text-lg mr-2"
                                >
                                    Ticket Description:
                                </label>

                                <textarea
                                    id="FormId5"
                                    v-model="ticket_info.ticket_comment"
                                    rows="3"
                                    :maxlength="255"
                                    class="shadow-md p-2 w-full block ml-2 focus:ring-blue-600 focus:border-blue-400 rounded-lg rounded-r-md sm:text-sm border-gray-500"
                                    placeholder="Write here..."
                                    style="resize: none"
                                ></textarea>

                                <p
                                    id="FormId5Text"
                                    class="font-semibold mt-2 text-sm text-gray-500"
                                >
                                    Please, write additional information
                                </p>
                            </div>

                            <div v-if="this.emailNotification">
                                <label
                                    for="ticket_comment"
                                    class="text-gray-600 font-semibold text-lg mr-2"
                                    >Notify to:</label
                                >
                                <div
                                    class="w-5/6 items-center mt-1 flex align-items-center justify-content-start"
                                >
                                    <multiselect
                                        v-model="usersToNotify"
                                        :custom-label="customFormat"
                                        :options="employeeList"
                                        :multiple="true"
                                        :close-on-select="false"
                                        :clear-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Select the users to notify"
                                        label="EmpID"
                                        track-by="EmpID"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>

                        <h4
                            class="font-medium leading-6 text-gray-700"
                            v-if="showSubQuestions"
                        >
                            Specific Questions
                        </h4>
                        <div
                            class="bg-gray-50 px-4 py-4 space-y-6 sm-p-6"
                            v-if="showSubQuestions"
                        >
                            <!-- SubCategory Specific Questions -->
                            <div
                                v-for="(question, Qindex) in getSubQuestions"
                                :key="Qindex"
                                :id="question.id"
                                class="sm:col-span-3 mr-2"
                            >
                                <div class="mb-1" :id="'question' + Qindex">
                                    {{ Qindex + 1 }}.
                                    {{ question.LabelTemplate }}
                                </div>

                                <div
                                    v-if="
                                        question.QuestionType === 'text' ||
                                        question.QuestionType === null
                                    "
                                    class="w-5/6 items-center mt-1 flex align-items-center justify-content-start"
                                >
                                    <input
                                        v-model="ticket_specific[Qindex].answer"
                                        type="text"
                                        :id="'answer' + Qindex"
                                        value=""
                                        placeholder="Type here..."
                                        class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-blue-600 focus:placeholder-gray-700 h-8 mb-1 py-2 rounded-md"
                                    />
                                </div>

                                <div
                                    v-if="question.QuestionType === 'number'"
                                    class="w-5/6 items-center mt-1 flex align-items-center justify-content-start"
                                >
                                    <input
                                        v-model="ticket_specific[Qindex].answer"
                                        type="number"
                                        :id="'answer' + Qindex"
                                        value=""
                                        placeholder="Type here..."
                                        class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-blue-600 focus:placeholder-gray-700 h-8 mb-1 py-2 rounded-md"
                                    />
                                </div>

                                <div
                                    v-if="question.QuestionType === 'date'"
                                    class="w-5/6 items-center mt-1 flex align-items-center justify-content-start"
                                >
                                    <input
                                        v-model="ticket_specific[Qindex].answer"
                                        type="date"
                                        :id="'answer' + Qindex"
                                        value=""
                                        placeholder="Type here..."
                                        class="bg-transparent border-b-2 border-gray-300 px-2 flex-1 focus:border-blue-600 focus:placeholder-gray-700 h-8 mb-1 py-2 rounded-md"
                                    />
                                </div>

                                <div
                                    v-if="question.QuestionType === 'time'"
                                    class="w-5/6 items-center mt-1 flex align-items-center justify-content-start"
                                >
                                    <input
                                        v-model="ticket_specific[Qindex].answer"
                                        type="time"
                                        :id="'answer' + Qindex"
                                        value=""
                                        placeholder="Type here..."
                                        class="bg-transparent ml-2 border-b-2 border-gray-300 px-2 flex-1 focus:border-blue-600 focus:placeholder-gray-700 h-8 mb-1 py-2 rounded-md"
                                    />
                                </div>

                                <div
                                    v-if="question.QuestionType === 'dropdown'"
                                    class="w-5/6 items-center mt-1 flex align-items-center justify-content-start"
                                >
                                    <select
                                        v-model="ticket_specific[Qindex].answer"
                                        class="w-full px-2 capitalize text-gray-400 focus:text-gray-700 focus:border-blue-600 border-b-2 py-2 rounded-md"
                                        :id="'answer' + Qindex"
                                    >
                                        <option value="" hidden>
                                            Select an Option...
                                        </option>
                                        <option
                                            v-for="(
                                                Answer, index
                                            ) in question.question_options"
                                            :value="Answer.optionLabel"
                                            :key="index"
                                        >
                                            {{ Answer.optionLabel }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="question.QuestionType == 'multiple'">
                                    <div class="flex">
                                        <div
                                            v-for="(
                                                Answer, index
                                            ) in question.question_options"
                                            :value="Answer.id"
                                            :key="index"
                                            class="flex px-2"
                                        >
                                            <label
                                                :for="'answer' + Qindex"
                                                class="items-center px-1 flex"
                                            >
                                                <input
                                                    type="checkbox"
                                                    :value="Answer.optionLabel"
                                                    v-model="
                                                        ticket_specific[Qindex]
                                                            .answer[index].value
                                                    "
                                                    :id="
                                                        'answer' +
                                                        Qindex +
                                                        'subAnswer' +
                                                        index
                                                    "
                                                    class="focus:ring-blue-400 h-4 w-4 rounded"
                                                />
                                                <span
                                                    class="ml-2 text-gray-500"
                                                    >{{
                                                        Answer.optionLabel
                                                    }}</span
                                                >
                                            </label>
                                        </div>
                                    </div>
                                    <label
                                        :id="'answer' + Qindex"
                                        class="size-xs text-gray-300"
                                        >Select at least one of the
                                        options</label
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="m-2">
                        <label class="text-gray-600 font-semibold text-lg mr-2"
                            >Add a proof (optional):</label
                        >
                        <div class="flex flex-row items-center">
                            <upload-files
                                @changed="handleImages"
                                :maxFiles="5"
                                maxError="the max size of the files must be less than 2MB"
                            ></upload-files>
                        </div>
                    </div>
                    <div class="px-4 py-2 text-right md:px-6">
                        <status-button @toggle="sendTicket" :status="status">
                            Send ticket
                        </status-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusButton from "../../../mockups/StatusButton";
import { mapActions, mapGetters, mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Multiselect from "vue-multiselect";
import uploadFiles from "@/views/Utilities/upload_files/index";
import MiniSearchDropdown from "@/views/components/Utilities/MiniSearchDropdown.vue";
export default {
    components: {
        Datepicker,
        Multiselect,
        "upload-files": uploadFiles,
        StatusButton,
        MiniSearchDropdown,
    },
    data() {
        return {
            ticket_info: {
                ticket_queue: null,
                ticket_category: null,
                ticket_subcategory: null,
                ticket_employeeId: null,
                ticket_issueDate: null,
                ticket_comment: null,
                ticket_wfh: false,
            },

            ticket_specific: [],
            emailNotification: false,
            usersToNotify: [],
            showCategories: false,
            showSubCategories: false,
            showSubQuestions: false,
            sending: false,
            isLoading: true,
            conq: "",
            proofIdx: 0,
            images: [],
            status: "",
        };
    },
    computed: {
        ...mapState(["tickets", "employees"]),
        ...mapGetters([
            "getEmployeesperCampaign",
            "getQueuesList",
            "getCategoriesList",
            "getSubCategoriesList",
            "getSubQuestions",
            "employeeList",
            "getSubQuestionsArray",
            "getFileForm",
        ]),
    },
    props: {},
    methods: {
        ...mapActions([
            "fetchEmployeesperCampaign",
            "fetchTicketQueues",
            "fetchTicketCategories",
            "fetchTicketSubCategories",
            "fetchTicketSubQuestions",
            "fetchTicketSubQuestionsArray",
            "sendNewTicket",
            "fetchAllEmployees",
        ]),
        formatEmployeeLabel(employee) {
            return `${employee.first_name} ${employee.LastName1} (${employee.EmpID})`;
        },
        customFormat({ EmpID, first_name, LastName1 }) {
            return `${first_name?.toUpperCase()} ${LastName1?.toUpperCase()} - [${EmpID?.toUpperCase()}]`;
        },
        customFormatter(date) {
            let newDate = moment(date).format("YYYY-MM-DD");
            this.ticket_info.ticket_issueDate = newDate;
            return newDate;
        },
        handleImages(files) {
            this.images = files;
        },
        loadCategories() {
            this.showSubQuestions = false;
            this.showCategories = false;
            this.showSubCategories = false;
            this.ticket_specific = [];
            this.ticket_info.ticket_category = null;
            this.ticket_info.ticket_subcategory = null;

            let objData = {
                queueSelected: this.ticket_info.ticket_queue,
            };
            this.fetchTicketCategories(objData).then(() => {
                this.showCategories = true;
            });
        },

        loadSubCategories() {
            this.showSubQuestions = false;
            this.showSubCategories = false;
            this.ticket_specific = [];
            this.ticket_info.ticket_subcategory = null;

            let objData = {
                categorySelected: this.ticket_info.ticket_category,
            };
            this.fetchTicketSubCategories(objData).then(() => {
                this.showSubCategories = true;
            });
        },
        resetComponent() {
            this.proofIdx++;
        },
        loadSubQuestions() {
            this.showSubQuestions = false;
            let objData = {
                queue: this.ticket_info.ticket_queue,
                category: this.ticket_info.ticket_category,
                subcategory: this.ticket_info.ticket_subcategory,
            };
            this.fetchTicketSubQuestions(objData).then(() => {
                if (this.getSubQuestions.length > 0) {
                    // in case of accepting multiple as question option
                    this.fetchTicketSubQuestionsArray(objData).then((res) => {
                        this.ticket_specific = res;
                        this.showSubQuestions = true;
                    });
                } else {
                    this.showSubQuestions = false;
                }
            });
        },
        sendTicket() {
            event.preventDefault();
            this.conq = "";
            let counter = [];

            let key = 0;
            for (var prop in this.ticket_info) {
                if (prop != "ticket_wfh") {
                    if (
                        this.ticket_info[prop] === null ||
                        this.ticket_info[prop] === undefined ||
                        this.ticket_info[prop] === ""
                    ) {
                        document
                            .getElementById("FormId" + key)
                            .classList.add("border-2", "border-red-400");
                        document
                            .getElementById("FormId" + key + "Text")
                            .classList.remove("font-semibold");
                        document
                            .getElementById("FormId" + key + "Text")
                            .classList.add("text-red-500", "font-light");
                        counter.push({ prop });
                    } else {
                        document
                            .getElementById("FormId" + key)
                            .classList.remove("border-2", "border-red-400");
                        document
                            .getElementById("FormId" + key + "Text")
                            .classList.remove("text-red-500", "font-light");
                        document
                            .getElementById("FormId" + key + "Text")
                            .classList.add("font-semibold");
                    }
                }
                key++;
            }

            if (this.showSubQuestions) {
                let newKey = 0;
                [].forEach.call(this.ticket_specific, (element) => {
                    if (
                        element.answer === null ||
                        element.answer === undefined ||
                        element.answer === ""
                    ) {
                        document
                            .getElementById("answer" + newKey)
                            .classList.add("border-2", "border-red-400");
                        counter.push({ wrongId: newKey });
                    } else {
                        document
                            .getElementById("answer" + newKey)
                            .classList.remove("border-2", "border-red-400");
                    }
                    newKey++;
                });
            }

            if (this.emailNotification) {
                if (this.usersToNotify.length === 0) {
                    this.$fire({
                        type: "warning",
                        title: "Oops!",
                        text: "Please, add users to notify",
                        showConfirmButton: true,
                        allowOutsideClick: false,
                    }).then(() => {
                        return false;
                    });
                }
            }

            if (counter.length > 0) {
                this.$fire({
                    type: "warning",
                    title: "Oops!",
                    text: "There's some missing information",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                });
            } else {
                this.status = "loading";
                let dataObject = new FormData();
                for (let image of this.images) {
                    dataObject.append("proof_tickets[]", image);
                }
                dataObject.append(
                    "ticket_info",
                    JSON.stringify(this.ticket_info)
                );
                dataObject.append(
                    "ticket_specific",
                    JSON.stringify(this.ticket_specific)
                );
                dataObject.append(
                    "users_to_notify",
                    JSON.stringify(this.usersToNotify)
                );
                this.sendNewTicket(dataObject)
                    .then(() => {
                        this.$fire({
                            type: "success",
                            title: "The ticket has been sent successfully",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then(() => {
                            this.$emit("closeModal", true);
                        });
                    })
                    .catch(() => {
                        this.status = "error";
                        this.$fire({
                            type: "warning",
                            title: "Something wrong",
                            text: "Please report to a system administrator",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        },
    },
    created() {
        // this.fetchEmployeesperCampaign();
        this.fetchTicketQueues();
        this.fetchAllEmployees().then(() => {
            this.isLoading = false;
        });
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.formTransfer {
    display: block !important;
}
.hide {
    display: none;
}
::placeholder {
    text-transform: capitalize;
}
</style>