<template>
  <li class="select-none w-full">
    <div class="flex w-full h-8 space-x-[0.2rem] hover:cursor-pointer">
      <div class="w-1.5 shrink-0 h-full rounded-sm" :class="borderColor"></div>
      <div class="flex hover:bg-gray-100 rounded-lg w-full text-left h-full items-center"
           :class="{'bg-gray-100': isSelected}">
        <div v-for="i in [...Array(nodeMargin/indentPixelSize).keys()]"
             :key="`${node.id}${node.type}${i}`"
             class="w-[17px] shrink-0 h-full border-r-2 left-2"
             @click.prevent="makeActiveElement"/>
        <div
            :class="{'hover:bg-gray-200': isExpandable}"
            class="shrink-0 px-2 rounded-lg h-full content-center text-[#007bff] stroke-[3]"
            @click.prevent="toggleChildren">
          <svg v-if="isExpandable && !showChildren" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24" stroke="currentColor" class="size-3.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
          </svg>
          <svg v-if="isExpandable && showChildren" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24" stroke="currentColor" class="size-3.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
          </svg>
        </div>
        <div
            class="flex w-full capitalize text-left h-full items-center overflow-hidden truncate pl-1"
            @click.prevent="makeActiveElement">
          <span class="text-sm" :class="{'text-[#E03A44]': !node.isActive}">{{ node.label }}</span>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <ul v-if="isExpandable && showChildren" class="w-full">
        <TreeNode
            ref="child"
            v-for="child in filteredChildren"
            :key="`${node.id}${child.type}${child.id}`"
            :node="child"
            :node-margin="nodeMargin + indentPixelSize"
            :show-children="showChildren"
            :main="main"
            :inherited-path="[...path]"
            :disabled="disabled"
        />
      </ul>
    </transition>
  </li>
</template>
<script>
export default {
    name: 'TreeNode',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        node: {
            type: Object,
            required: true,
        },
        nodeMargin: {
            type: Number,
            default: 0,
        },
        main: {
            type: Object,
            required: true
        },
        inheritedPath: {
            type: Array,
            default: () => [],
        }
    },
    data () {
        return {
            indentPixelSize: 17,
        };
    },
    computed: {
        showChildren () {
            return this.main.expanded.includes(`${this.node.id}${this.node.type}`);
        },
        path() {
            if ( this.inheritedPath.length === 0 ) {
                return [this.node];
            } else {
                return [...this.inheritedPath, this.node];
            }
        },
        borderColor () {
            return {
                'bg-[#007bff]': this.isSelected,
                'bg-transparent': !this.isSelected,
            }
        },
        isExpandable () {
            return this.hasChildren();
        },
        isSelected () {
            const mainElement = this.main.activeElement;
            return `${ mainElement.id }${ mainElement.type }` === `${ this.node.id }${ this.node.type }`;
        },
        filteredChildren () {
            if ( this.main.hideInactive === true) {
                return this.node.children.filter(x => x.isActive === 1);
            } else {
                return this.node.children;
            }
        },
    },
    methods: {
        hasChildren (node = this.node) {
            const { children } = node;
            return children !== undefined && children.length > 0;
        },
        closeChildren () {
            if ( [0, undefined].includes(this.$refs?.child?.length) ) {
                return;
            }

            this.$refs.child.forEach((child) => {
                if ( child.showChildren === true ){
                    child.toggleChildren();
                }
                child.closeChildren();
            })
        },
        toggleChildren () {
            if ( this.disabled ) {
                return;
            }

            if ( !this.isExpandable ) {
                this.makeActiveElement();
                return
            }

            if ( this.showChildren === true ) {
                this.main.expanded = this.main.expanded.filter(x => x !== `${this.node.id}${this.node.type}`)
            } else {
                this.main.expanded.push(`${this.node.id}${this.node.type}`)
            }

            if ( this.showChildren === false ) {
                this.closeChildren();
            }
        },
        makeActiveElement () {
            if ( this.disabled ) {
                return;
            }

            if ( !this.showChildren && this.isExpandable ) {
                this.toggleChildren()
            }

            this.main.activePath = this.path;
        },
    },
}
</script>
<style>
.slide-fade-enter-active {
    transition: all .20s ease;
}

.slide-fade-leave-active {
    transition: all .20s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateY(-15px);
    opacity: 0;
}

</style>