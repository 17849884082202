import { render, staticRenderFns } from "./userTicketView.vue?vue&type=template&id=5b874428&scoped=true"
import script from "./userTicketView.vue?vue&type=script&lang=js"
export * from "./userTicketView.vue?vue&type=script&lang=js"
import style0 from "./userTicketView.vue?vue&type=style&index=0&id=5b874428&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b874428",
  null
  
)

export default component.exports