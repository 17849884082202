<template>
  <div class="h-[calc(100vh-7.25rem)] overflow-hidden flex flex-col rounded-lg shadow-md">
    <div class="flex flex-row space-x-2 w-full bg-white shadow-lg p-2 items-stretch">
      <ticket-searchbar :key="resetKey" @toggle="filterUpdate"/>
      <button class="transition-colors duration-300 rounded-md uppercase text-xs p-2 bg-gray-500 text-white hover:bg-gray-700"  @click.prevent="clearFilters">
        <span class="text-nowrap">Clear <i class="bi bi-funnel-fill"></i></span>
      </button>
    </div>
    <itemstable :filters="form" @updateTable="filterUpdate" :key="componentKey" @toggle="keepQueryAlive"></itemstable>
    <TailwindPagination class="shadow-none m-1" :data="getFilterTicketsPagination" @pagination-change-page="getResults" :limit="limit" :keep-length="keepLength"/>
  </div>
</template>
<script>
    import itemstable        from'@/views/components/Tickets/tables/tickets_per_queue';
    import TicketSearchbar from '@/views/components/Tickets/TicketSearchBar/TicketsSearchBar'
    import {mapGetters,mapState, mapActions } from 'vuex'
    import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
    export default {

        components: {
            TailwindPagination,
            itemstable,
            TicketSearchbar
        },
        
        data(){
            return{
                limit:          2,
                keepLength:     true,
                componentKey:   0,
                resetKey:       0,
                itemSelected:   null,
                isLoading:      true,
                showTable:      false,
                form:{
                    page: 1,
                    refresh :false
                }
            }
        },

        computed: {
            ...mapState([
            ]),
            ...mapGetters([
                'getFilterTicketsPagination'
                ])
        },

        created(){
            this.OpenTable();
        },

        methods: {

            ...mapActions([
                'filterTicketsRequest'
            ]),

            OpenTable: function () {
                this.isLoading = true

                this.filterTicketsRequest(this.form).then(() => {
                    this.isLoading = false;
                    this.showTable = true;
                })
            },

            filterUpdate: function (form = null) {
                if (form === null) {
                    this.filterTicketsRequest(this.form);
                } else {
                    this.form = form;
                }
            },

            keepQueryAlive: function () {
                this.componentKey++;
            },

            forceRerender() {
                this.OpenTable();
            },

            clearFilters: function () {
                this.resetKey++;
            },

            getResults(page) {
                this.form.page = page;
                this.filterTicketsRequest(this.form);
            },

        },
        watch:{
            form:{
            handler:function(){
                this.filterTicketsRequest(this.form);
            },
            deep:true
            }
        },

      mounted(){
          this.$emit('set-title', 'Ticket Management')
      }

    }
</script>