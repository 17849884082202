<template>
    <div>
        <loading :active.sync="loadingFlag" :loader="loader" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <div class="space-y-3">
            <div class="flex">
                <search-bar class="bg-red mx-auto" :key="resetKey" @toggle="filterUpdate" @clear="clearFilters"/>
            </div>
            <TailwindPagination :data="getEmployeePagination" @pagination-change-page="getResults" :limit="limit" :keep-length="keepLength"/>
            <table class="table-auto light-table w-full">
                <thead class="bg-gray-50">
                <tr>
                    <th class="w-1/3">
                        Employee
                    </th>
                    <th class="px-1">
                        Supervisor
                    </th>
                    <th class="w-20 px-1">
                        Location
                    </th>
                    <th class="px-1">
                        Campaign
                    </th>
                    <th class="w-40 px-1">
                        Tenure
                    </th>
                    <th class="w-5">

                    </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200" v-if="employeeList.length > 0">
                <tr :id="employee.RecID" v-for="(employee) in employeeList" :key="employee.RecID" class="gap-2">
                    <td class="overflow-hidden pr-2">
                        <div class="flex items-center gap-2">
                            <div id="avatar_image" class="relative shrink-0">
                                <avatar :employeeId="employee.EmpID" :id="'avatar'+employee.EmpID" class="w-10 h-10 text-2xl"/>
                                <span class="w-3 h-3 absolute -top-1 -left-1 rounded-full"
                                      :class="{ 'bg-green-500' : employee.Status === 'Active',
                      'bg-red-500' : employee.Status === 'Inactive',
                      'bg-yellow-500' : (employee.Status !== 'Active' && employee.Status !== 'Inactive')}"></span>
                            </div>
                            <div class="my-auto uppercase text-gray-700 overflow-hidden py-1">
                                <div class="whitespace-nowrap text-xs text-gray-400 text-left uppercase">
                                    <i class="bi bi-person-vcard"></i> {{ employee.EmpID }}
                                </div>
                                <div class="text-gray-600 text-left uppercase overflow-hidden text-ellipsis" :title="employee.FullName">
                                    {{ employee.FullName }}
                                </div>
                                <span class="text-xs bg-gray-100 text-gray-400 rounded-full px-2.5 py-0.5 border-gray-400 border-[1px] me-1 uppercase">
                        <i class="bi bi-briefcase"></i> {{ employee.Title }}
                      </span>
                            </div>
                        </div>
                    </td>
                    <td class="uppercase overflow-hidden text-ellipsis px-1">
                        <div class="my-auto uppercase text-gray-700 overflow-hidden text-ellipsis py-1">
                            <div class="whitespace-nowrap text-xs text-gray-400 text-left uppercase" :title="employee.SupervisorId">
                                <i class="bi bi-person-vcard"></i> {{ employee.SupervisorId }}
                            </div>
                            <div class="text-gray-600 text-left uppercase overflow-hidden text-ellipsis" :title="employee.Supervisor">
                                {{ employee.Supervisor }}
                            </div>
                            <span class="text-xs bg-gray-100 text-gray-400 rounded-full px-2.5 py-0.5 border-gray-400 border-[1px] me-1 uppercase whitespace-wrap" :title="employee.Team">
                      <i class="bi bi-people"></i> {{ employee.Team }}
                    </span>
                        </div>
                    </td>
                    <td class="uppercase overflow-hidden text-ellipsis px-1" :tittle="employee.Location">
                        <i class="bi bi-geo-alt"></i>
                        {{ employee.Location }}
                    </td>
                    <td class="uppercase overflow-hidden text-ellipsis px-1" :tittle="employee.Campaign">
                        <div class="text-gray-600 text-left uppercase overflow-hidden text-ellipsis" :title="employee.Campaign">
                            <i class="bi bi-house"></i> {{ employee.Campaign }}
                        </div>
                        <span class="text-xs bg-gray-100 text-gray-400 rounded-full px-2.5 py-0.5 border-gray-400 border-[1px] me-1 uppercase" :title="employee.LOB">
                      LOB: {{ employee.LOB }}
                    </span>
                    </td>
                    <td class="uppercase text-wrap overflow-hidden px-1" :title="employee.Tenure">
                        <i class="bi bi-calendar4-event"></i>
                        {{ employee.Tenure }}
                    </td>
                    <!-- actions -->
                    <td class="relative group p-0">
                        <button class="flex font-semibold items-center m-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </button>

                        <div class="inline-block absolute -left-20 z-35">
                            <div class="bg-white shadow-md rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-200 ease-in-out origin-top min-w-32 -left-10 z-10">
                                <button class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                                        v-if="itemContains('SystemAdministrator') || itemContains('HR')" @click.prevent="gotoProfile(employee)">
                                    <i class="bi bi-person-fill scale-125"></i>
                                    Profile
                                </button>
                                <button class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                                        v-if="itemContains('SystemAdministrator') || itemContains('Engagement')" @click.prevent="$router.push({ name: 'surveys.medical.answers', params: { id: employee.EmpID } })">
                                    <i class="bi bi-clipboard2-pulse scale-125"></i>
                                    Medical Survey Answers
                                </button>
                                <button class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                                        v-if="itemContains('SystemAdministrator')" @click.prevent="$router.push({  path: '/management/roles/'+employee.EmpID })">
                                    <i class="bi bi-key-fill rotate-45 scale-125"></i>
                                    Permissions
                                </button>
                                <button class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                                        v-if="itemContains('SystemAdministrator') || itemContains('Staff')" @click.prevent='showViewModal(employee)'>
                                    <i class="bi bi-fingerprint scale-125"></i>
                                    Password
                                </button>
                                <button class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2 whitespace-nowrap"
                                        v-if="itemContains('SystemAdministrator') || itemContains('Staff')" @click.prevent="$router.push({ name: 'incidences.create', params: { recid: employee.RecID, id: employee.EmpID } })">
                                    <i class="bi bi-person-exclamation scale-125"></i>
                                    Employee Change
                                </button>
                                <button class="bg-gray-50 text-gray-900 hover:bg-gray-700 hover:text-white w-full px-3 py-2 rounded-sm shadow-sm text-left flex gap-2"
                                        v-if="itemContains('SystemAdministrator')" @click.prevent="loginAs(employee.EmpID)">
                                    <i class="bi bi-box-arrow-in-right scale-125"></i>
                                    Login As
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody class="bg-white divide-y divide-gray-200" v-else>
                <tr>
                    <td class="p-4 whitespace-nowrap">
                        No results found.
                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import permissions from '@/config/mixins'
import ChangePasswordModal from "@/views/components/Modal/changePassword";
import SearchBar from '@/views/components/Utilities/editEmployeeSearchBar/search-bar'
import Avatar from '@/views/components/Utilities/avatar'
import TailwindPagination from "@/views/components/Pagination/TailwindPagination.vue";
import { EventBus } from "@/event-bus";
export default {
    mixins: [permissions],
    data() {
        return {
            limit: 2,
            keepLength: true,
            keyword: '',
            fullPage: true,
            color: "#F2F2F2",
            bgcolor: "#000000",
            loader: 'dots',
            isDisabled: true,
            showModal: false,
            employee: '',
            resetKey: 0,
            form: {
                refresh: false,
                page: 1,
            }
        }
    },
    components: {
        TailwindPagination,
        Loading,
        SearchBar,
        Avatar
    },
    computed: {
        ...mapState(['employees']),
        ...mapGetters(['employeeList', 'loadingFlag', 'getEmployeePagination'])
    },
    created() {
        this.searchEmployee(this.form);
    },
    methods: {
        ...mapActions(['searchEmployee', 'loginAsEmployee']),
        gotoProfile(val) {
            this.$router.push({name: 'profileEmployee.personal', params: {id: val.RecID}});
        },
        itemContains(n) {
            return this.hasPermission(n)
        },
        showViewModal: function (id) {
            this.showModal = true;
            this.employee = id;
            EventBus.$emit("open-modal", ChangePasswordModal, {
                profileInfo: id,
            });
        },
        closeViewModal: function () {
            this.showModal = false;
            this.employee = '';
        },
        filterUpdate: function (form) {
            this.form = form;
        },
        clearFilters: function () {
            this.resetKey++;
        },
        loginAs: function (data) {
            let employeeObj = {EmpID: data};
            this.loginAsEmployee(employeeObj).then(() => {
                window.location.href = '/';
            });
        },
        getResults(page) {
            this.form.page = page;
            this.searchEmployee(this.form);
        },
    },
    watch: {
        form: {
            handler: function () {
                this.searchEmployee(this.form);
            },
            deep: true
        }
    },
}
</script>
<style lang="scss" scoped>
#avatar_image {
    height: 35px !important;
    width: 35px !important;
}

.group:hover .group-hover\:scale-100 {
    transform: scale(1);
}

.scale-0 {
    transform: scale(0)
}

table {
    table-layout: fixed;
    width: 100%;
}
</style>