<template>
    <div class="flex flex-col lg:w-10/12 mx-auto px-3 mb-5">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow-sm bg-white overflow-hidden border-b border-gray-200 sm:rounded-md" v-if="!loading">
                    <candidates-searchbar @toggle="filter" @refresh="refresh" :key="resetKey"></candidates-searchbar>
                    <table class="table w-full divide-y divide-gray-200 table-auto text-ellipsis">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                                    Register date
                                </th>
                                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                                    SITE
                                </th>
                                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                                    CURP
                                </th>
                                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                                    Full name
                                </th>
                                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase  text-ellipsis whitespace-nowrap">
                                    Status
                                </th>
                                <th style="max-width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase relative  text-ellipsis whitespace-nowrap">
                                    FILES
                                </th>
                                <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-ellipsis whitespace-nowrap">
                                    Source
                                </th>
                                <th scope="col" class="relative px-6 py-3 uppercase  text-center text-ellipsis whitespace-nowrap">
                                    Actions
                                </th>

                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200" v-if="getCandidates.length">
                            <tr v-for="(item, key) in getCandidates" :key="key">
                                <td class="px-6 py-3 text-ellipsis whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">
                                        {{ item.register_date }}
                                    </div>
                                </td>
                                <td class="px-6 py-3 text-ellipsis whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">
                                        {{ item.site }}
                                    </div>
                                </td>
                                <td class="px-6 py-3 text-ellipsis whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">
                                        {{ item.curp }}
                                    </div>
                                </td>

                                <td class="px-6 py-3 text-ellipsis whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-900 capitalize text-left">
                                            {{ item.first_last_name }} {{ item.second_last_name }} {{ item.name }} {{ item.middle_name }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-3  text-ellipsis whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">
                                        {{ item.status }}
                                    </div>
                                </td>

                                <td style="max-width: 155px; padding:1em; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="px-6 py-3 w-44 overflow-hidden text-ellipsis whitespace-nowrap">
                                    <div class="flex items-center text-ellipsis">
                                        <div class="text-xs text-gray-900 capitalize text-center cursor-pointer" :title="item.files.join(' ,')">
                                            {{ item.files.length > 0 ? item.files.join(', ') : 'N/A' }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-3 w-1/8 text-ellipsis whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">
                                        {{ item.source }}
                                    </div>
                                </td>

                                <td class="px-6 py-3 w-1/8 text-ellipsis whitespace-nowrap">
                                    <div class="flex flex-row items-center justify-center">
                                        <a href="#" class="mx-1 text-gray-300 hover:shadow-lg hover:text-green-400 p-2 rounded-full" @click.prevent="$router.push({ path: '/recruitment/candidates/' + item.id })" :title="'Edit Candidate ' + item.name + ' ' + item.first_last_name">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                                            </svg>
                                        </a>
                                        <a href="#" title="Edit status" @click="updateStatus(item.id)" class="mx-1 text-gray-300 hover:shadow-lg hover:text-blue-400 p-2 rounded-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                            </svg>
                                        </a>
                                        <a href="#" title="Release candidate" @click="terminate(item.id)" class="mx-1 text-gray-300 hover:shadow-lg hover:text-red-400 p-2 rounded-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                            </svg>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody class="bg-white divide-y divide-gray-200" v-else>
                            <tr>
                                <td class="px-6 py-3whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">
                                        No records found.
                                    </div>
                                </td>
                                <td class="px-6 py-3whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">

                                    </div>
                                </td>
                                <td class="px-6 py-3whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left">

                                    </div>
                                </td>
                                <td class="px-6 py-3 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-900 capitalize text-left">

                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-3 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-900 capitalize text-left">

                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-3whitespace-nowrap">
                                    <div class="text-sm text-gray-900 capitalize text-left"></div>
                                </td>

                                <td class="px-6 py-4  whitespace-nowrap text-sm font-medium border-b-4 border-gray-50">
                                    <div class="flex flex-row items-center justify-center">

                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                </div>
                <div v-else>
                    Loading candidates
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CandidatesSearchbar from '../../components/searchbars/CandidatesSearchbar';
import { mapActions, mapGetters } from 'vuex'
export default {
    components: { 'candidates-searchbar': CandidatesSearchbar },
    computed: {
        ...mapGetters(['getCandidates']),
        hasData: function () {
            return this.getCandidates.length > 0;
        }
    },
    methods: {
        ...mapActions(['fetchCandidates', 'terminateCandidate']),
        terminate(candidate) {
            this.$emit('release', candidate);
        },
        updateStatus: function (candidate) {
            this.$emit('update', candidate);
        },
        filter: function (formData) {
            this.$emit('filter', formData);
        },
        refresh: function () {
            this.resetKey++;
        }
    },
    watch: {
        selectedCandidate: function () {
            this.fetchCandidates();
        }
    },
    created() {
        this.loading = true;
        this.fetchCandidates().then(() => {
            this.loading = false;
        });
    },
    data() {
        return {
            candidates: this.getCandidates,
            reason: "",
            resetKey: 0,
            loading: false
        }
    },
    props: {
        selectedCandidate: {
            type: Number,
            default: 0
        }
    },
}
</script>