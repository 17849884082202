<template>
    <div class="relative pt-4 mb-2">
        <div class="mx-auto max-w-7xl my-2 pl-5 text-center text-gray-500 text-sm font-medium">
            Fill the text input using the candidate name, last name or CURP or
            filter by source using the dropdown menu.
        </div>

        <!--Table-->
        <candidates-table :key="resetKey" @filter="filter" @release="release" @update="updateStatus" />

        <!--#StatusModal-->
        <modal @close="resetStatus" :show="editStatus">
            <form @submit.prevent="saveStatus" class="p-4">
                <h4 class="mb-2 text-gray-500">Update status</h4>
                <select required v-model="form.status"
                    class="relative w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400">
                    <option value="">Select a status...</option>
                    <option :value="item.id" v-for="(item, index) in transitStatus" :key="'status' + index">
                        {{ item.status }}
                    </option>
                </select>
                <textarea rows="5" style="resize: none" v-model="form.comment" maxlength="250"
                    placeholder="Write a comment to complement the status change."
                    class="mt-2 relative w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400">
                </textarea>
                <span class="text-left text-sm text-gray-400">{{ form.comment.length }} out of 250
                    characters.</span>
                <div class="text-right">
                    <button :disabled="saving" type="submit"
                        class="ml-4 bg-gray-700 shadow-md text-white rounded-sm hover:bg-gray-900 p-2 px-4">
                        <span v-if="saving">
                            Saving
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce"
                                viewBox="0 0 20 20" fill="currentColor">
                                <path
                                    d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                                <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                            </svg>
                        </span>
                        <span v-else> Save </span>
                    </button>
                </div>
            </form>
        </modal>

        <!--#TerminationModal-->
        <modal v-if="terminate" @close="resetStatus">
            <template>
                <form @submit.prevent="releaseCandidate">
                    <h4 class="mb-2 text-gray-500">Release candidate</h4>
                    <select required v-model="form.status"
                        class="relative w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400">
                        <option value="">Select a status...</option>
                        <option :value="item.id" v-for="(item, index) in releaseStatus" :key="'status' + index">
                            {{ item.status }}
                        </option>
                    </select>
                    <textarea rows="5" style="resize: none" v-model="form.comment" maxlength="250"
                        placeholder="Write a comment to complement the status change."
                        class="mt-2 relative w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400">
                    </textarea>
                    <span class="text-left text-sm text-gray-400">{{ form.comment.length }} out of 250
                        characters.</span>
                    <div class="text-right">
                        <button :disabled="saving" type="submit"
                            class="ml-4 bg-gray-700 shadow-md text-white rounded-sm hover:bg-gray-900 p-2 px-4">
                            <span v-if="saving">
                                Saving
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce"
                                    viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                                    <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                                </svg>
                            </span>
                            <span v-else> Save </span>
                        </button>
                    </div>
                </form>
            </template>
        </modal>
    </div>
</template>
<script>
import CandidatesTable from "../../components/tables/Candidates.vue";
// import Modal from "../../components/modals/ChangeStatus.vue";
import Modal from "@/views/Utilities/Modal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        CandidatesTable,
        Modal,
    },
    data() {
        return {
            showSlideOver: false,
            resetKey: 0,
            editStatus: false,
            terminate: false,
            form: {
                id: null,
                status: "",
                comment: "",
            },
            saving: false,
        };
    },
    methods: {
        ...mapActions([
            "filterCandidates",
            "fetchCandidates",
            "fetchCandidateStatus",
            "updateCandidateStatus",
        ]),
        openSlideOver: function () {
            this.showSlideOver = true;
        },
        filter: function (formData) {
            this.filterCandidates(formData);
        },
        refreshTable() {
            this.resetKey++;
        },
        updateStatus(candidate) {
            this.form.id = candidate;
            this.editStatus = true;
        },
        resetStatus() {
            this.editStatus = false;
            this.terminate = false;
            this.form.id = null;
            this.form.status = "";
            this.form.comment = "";
        },
        saveStatus() {
            this.saving = true;
            this.updateCandidateStatus({
                id: this.form.id,
                status_id: this.form.status,
                comment: this.form.comment,
            }).then(() => {
                this.saving = false;
                this.$fire("Status updated successfully.").then(() => {
                    this.$router.go(0);
                });
            });
        },
        release(candidate) {
            this.form.id = candidate;
            this.terminate = true;
        },
        async releaseCandidate() {
            this.saving = true;
            this.form.type = "termination";
            const res = await this.$fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
            });

            if (res.dismiss) {
                return (this.saving = false);
            }
            this.updateCandidateStatus({
                id: this.form.id,
                status_id: this.form.status,
                comment: this.form.comment,
                type: "termination",
            }).then(() => {
                this.saving = false;
                this.$fire("Status updated successfully.").then(() => {
                    this.$router.go(0);
                });
            });
        },
    },
    mounted() {
        this.$emit("set-title", "Candidates List");
    },
    created() {
        this.fetchCandidates();
        this.fetchCandidateStatus();
    },
    computed: {
        ...mapGetters(["getCandidates", "getStatusList"]),
        transitStatus() {
            return this.getStatusList.filter((x) => x.type == 1);
        },
        releaseStatus() {
            return this.getStatusList.filter((x) => x.type == 2);
        },
    },
};
</script>