import { render, staticRenderFns } from "./document_view.vue?vue&type=template&id=855fe586&scoped=true"
import script from "./document_view.vue?vue&type=script&lang=js"
export * from "./document_view.vue?vue&type=script&lang=js"
import style0 from "./document_view.vue?vue&type=style&index=0&id=855fe586&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855fe586",
  null
  
)

export default component.exports