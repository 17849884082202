import { render, staticRenderFns } from "./mark-as-lost.vue?vue&type=template&id=a287e608&scoped=true"
import script from "./mark-as-lost.vue?vue&type=script&lang=js"
export * from "./mark-as-lost.vue?vue&type=script&lang=js"
import style0 from "./mark-as-lost.vue?vue&type=style&index=0&id=a287e608&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a287e608",
  null
  
)

export default component.exports