<template>
    <select required
            class="transition-colors duration-300 shadow-sm rounded-lg px-3 py-2 bg-transparent hover:text-gray-600 focus:text-gray-600 w-32"
            v-model="selectedAssignation">
        <optgroup label="Assignation status">
            <option hidden value="">Assignation Status</option>
            <option value="1">Not Assigned</option>
            <option value="0">Assigned</option>
        </optgroup>
    </select>
</template>

<script>
import Debounce from 'debounce';
export default {
  data(){
      return{ 
        selectedAssignation: ""
      }
  },
  watch:{
    selectedAssignation: Debounce(function(){
        this.$emit('toggle',{
            key: 'isAssigned',
            value: this.selectedAssignation
        });
    },200)     

  }
}
</script>
