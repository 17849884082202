<template>
    <div :v-show="is_authenticated" class="grid grid-cols-[15rem_1fr] grid-rows-[100vh] overflow-hidden bg-gray-100" id="layout-wrapper">
        <clocker-component v-if="!!this.username"></clocker-component>
        <sidebar :hidden="!sidebarIsVisible" @toggle="toggleSidebar"></sidebar>
        
        <div class="flex flex-col overflow-auto">
            <header class="sticky my-3 mx-3 top-0 left-10 right-10 z-10" v-if="!!this.username">
                <navbar
                    v-show="userIsActive"
                    :title="title"
                    :username="username"
                    @toggle="toggleSidebar" />
            </header>
            
            <main class="grow px-3">
                <div id="page_start"></div>
                <slot></slot>
            </main>
            <modal :isVisible="show_modal" @close="closeModal">
                <component :is="modal_content" v-bind="modal_props"></component>
            </modal>
        </div>
    </div>
</template>
<script>
import debounce from "debounce";
import NotificationMenu from "@/views/notifications/layout";
import Sidebar from "@/views/Utilities/Sidebar";
import Navbar from "@/views/Utilities/Navbar";
import ClockerComponent from "@/views/clocker/main";
import Modal from "@/views/components/Modal/modalBase";
import auth from "@/config/user";
import { EventBus } from "@/event-bus";
export default {
    name: "Layout",
    components: {
        notifications: NotificationMenu,
        ClockerComponent,
        Sidebar,
        Navbar,
        Modal,
    },
    data() {
        return {
            show_sidebar: true,
            route: this.$route.name,
            is_authenticated: false,
            show_modal: false,
            modal_content: null,
            modal_props: {},
        };
    },
    methods: {
        toggleSidebar: debounce(function () {
            this.show_sidebar = !this.show_sidebar;
        }, 200),

        openModal(component, props = {}) {
            this.modal_content = component;
            this.modal_props = props;
            this.show_modal = true;
        },
        closeModal() {
            this.show_modal = false;
            this.modal_content = null;
            this.modal_props = {};
        },
    },
    computed: {
        sidebarIsVisible: function () {
            return this.show_sidebar && this.userIsActive;
        },
        userIsActive: function () {
            return auth.currentUser?.status?.toLowerCase() === "active";
        },
    },
    props: {
        username: {
            type: String,
            default: "",
        },
        title: {
            default: "Dashboard",
            type: String,
        },
    },
    created() {
        var hasToken = auth.currentUser?.token;
        this.is_authenticated = hasToken !== "" && hasToken !== undefined;

        EventBus.$on("open-modal", (component, props) => {
            this.openModal(component, props);
        });
    },
    beforeDestroy() {
        EventBus.$off("open-modal");
    },
    watch: {
        $route: function () {
            this.show_sidebar = true;
        },
    },
};
</script>
<style>
main {
    scroll-behavior: smooth !important;
}
.text-red-150 {
    color: #ff454f;
}
</style>