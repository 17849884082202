import { render, staticRenderFns } from "./create_survey.vue?vue&type=template&id=9baa35cc&scoped=true"
import script from "./create_survey.vue?vue&type=script&lang=js"
export * from "./create_survey.vue?vue&type=script&lang=js"
import style0 from "./create_survey.vue?vue&type=style&index=0&id=9baa35cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9baa35cc",
  null
  
)

export default component.exports