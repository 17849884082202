<template>
    <div class="relative w-full" ref="dropdown">
        <input
            type="text"
            v-model="searchTerm"
            :placeholder="placeholder"
            @focus="isDropdownVisible = true"
            class="w-full px-4 py-2 border border-gray-300 rounded-lg uppercase"
        />
        <ul
            v-if="isDropdownVisible"
            class="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-48 overflow-y-auto"
        >
            <li
                v-for="option in filteredOptions"
                :key="option[valueKey]"
                @mousedown.prevent="selectOption(option)"
                class="px-4 py-2 cursor-pointer hover:bg-c-primary hover:text-white uppercase"
            >
                {{ formatLabel(option) }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "SearchableDropdown",
    props: {
        options: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        valueKey: {
            type: String,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            default: "Select an option...",
        },
        value: {
            type: [String, Number, Object],
            default: null,
        },
        customFormatter: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            searchTerm: "",
            isDropdownVisible: false,
        };
    },
    computed: {
        filteredOptions() {
            return this.options.filter((option) =>
                this.formatLabel(option)
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase())
            );
        },
    },
    methods: {
        formatLabel(option) {
            return this.customFormatter
                ? this.customFormatter(option)
                : option[this.labelKey];
        },
        selectOption(option) {
            const emittedValue = this.valueKey ? option[this.valueKey] : option;
            this.$emit("input", emittedValue);
            this.searchTerm = this.formatLabel(option);
            this.isDropdownVisible = false;
        },
        handleClickOutside(event) {
            if (
                this.$refs.dropdown &&
                !this.$refs.dropdown.contains(event.target)
            ) {
                this.isDropdownVisible = false;
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
};
</script>